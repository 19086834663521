import { deleteRequest, getRequest, postRequest, putRequest } from '..';

export function add(data) {
  return postRequest('/users', data);
}

export function edit(data) {
  return putRequest('/users', data)
}

export function get(id) {
  return getRequest(`/users/${id}`);
}

export function getAll() {
  return getRequest('/users');
}

export function remove(id) {
  return deleteRequest(`/users/delete/${id}`);
}

export function sendInactiveInvitations() {
  return getRequest(`/account/all/sent-initial-invitation`);
}

export function sendInvitation(id) {
  return getRequest(`/account/${id}/sent-initial-invitation`);
}

export function getInviteLink(id) {
  return getRequest(`/account/${id}/invite-link`)
}