<template>
  <div class="dropdown w-100">
    <label v-if="label">{{ label }}</label>
    <br/>
    <a 
      id="user-select-dropdown"
      href="#" 
      class="btn bg-gradient-primary dropdown-toggle d-flex justify-content-between align-items-center px-3 overflow-visible w-100"
      :class="{ 'disabled': disabled }"
      data-bs-toggle="dropdown"
      :disabled="disabled"
    >
      <span
        v-if="!noOptionSelected"
        class="d-flex align-items-center"
      >
        <div
          class="user-profile-image float-start user-profile-image float-start border-radius-lg overflow-hidden ratio ratio-1x1 border-radius-lg mt-n4"
          :style="{
            width: '40px',
            backgroundSize: 'cover',
            backgroundImage: this.getBackgroundImageUrl(selectedImage)
          }"
        ></div>
        <span
          class="ms-2"
        >
          {{ selectedLabel }} 
        </span>
      </span>

      <span v-if="noOptionSelected">
        Geen {{ placeholderLabel }} geselecteerd
      </span>
    </a>
    <ul class="dropdown-menu w-100" aria-labelledby="dropdown-menu">
      <input 
        v-if="showSearchBar"
        class="form-control border px-2"
        placeholder="Vul zoektekst in"
        type="text"
        @input="setFilterText"
      />
      <li
        v-for="option in options.filter(o => 
          o.label.toLowerCase().includes(filterText.toLowerCase())
        )"
        v-bind:key="option.id"
        class="px-1 py-2"
        @click="selectOption(option.value)"
      >
        <div
          v-show="option.image"
          class="user-profile-image float-start user-profile-image float-start border-radius-lg overflow-hidden ratio ratio-1x1 border-radius-lg"
          :style="{
            width: '30px',
            backgroundSize: 'cover',
            backgroundImage: this.getBackgroundImageUrl(option.image)
          }" 
        ></div>
        <span
          class="ms-2"
        >
          {{ option.label }} 
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
    name: 'Dropdown',

    props:
    {
      value: { type: [Number, String], default: undefined },
      options: { type: Array, required: true },
      label: { type: String, default: undefined },
      placeholderLabel: { type: String, default: 'gebruiker' },
      showSearchBar: { type: Boolean, default: true },
      disabled: { type: Boolean },
    },

    emits: [
      'update:value',
    ],
    
    data() {
      return {
        selectedOption: {
            value: undefined,
            label: '',
            image: undefined
        },
        filterText: ''
      }
    },

    computed: {
      noOptionSelected() { return this.selectedOption.value === undefined; },

      currentValueHandler: {
        get() { return this.selectedValue ?? this.value },
        set(value) {
          const option = this.getOption(value);
          if(option !== undefined)
          {
            this.updateDisplayedOption(option)
            this.$emit('update:value', value)
          }
        }
      },

      selectedLabel() { return this.selectedOption.label },
      selectedValue() { return this.selectedOption.value },
      selectedImage() { return this.selectedOption.image }
    },

    mounted() {
      this.initialize()
    },

    updated() {
      const option = this.getOption(this.currentValueHandler)
      if(option !== undefined) this.updateDisplayedOption(option)
    },

    methods: {
      initialize: function() {
        const initialOption = this.getOption(this.initialValue)

        if(initialOption !== undefined) {
          this.updateDisplayedOption(initialOption)
        }
      },

      getOption: function(value) {
        return this.options.find(o => o.value === value)
      },

      updateDisplayedOption: function(option)
      {
        this.selectedOption.value = option.value;
        this.selectedOption.label = option.label;
        this.selectedOption.image = option.image;
      },

      selectOption: function(id)
      {
        this.currentValueHandler = id
      },
      
      getBackgroundImageUrl(url)
      {
        return url != null
        ? `url(${url})`
        : 'url(/assets/img/company/user_placeholder.png)'
      },

      setFilterText(inputEvent)
      {
        this.filterText = inputEvent.target.value;
      }
    }
}
</script>

<style scoped>
  .dropdown {
    position: relative;
  }

  .dropdown-menu {
    padding-top: 50px;
    max-height: 350px;
    overflow: scroll;
  }

  .dropdown-menu > input {
    border-color: #eee;
    width: calc(100% - 20px);
    position: absolute;
    top: 10px;
    left: 10px;
  }
</style>