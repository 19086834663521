export function parseDateTimeRange(startDateTimeString, endDateTimeString) {
  const startDateTime = new Date(startDateTimeString),
        startDateTimeIsValid = !isNaN(startDateTime),
        endDateTime = new Date(endDateTimeString),
        endDateTimeIsValid = !isNaN(endDateTime);

  if (!startDateTimeIsValid) {
    return 'datum // tijd';
  }

  const locales = 'nl-NL',
        dateFormatOptions = {
          dateStyle: 'short',
        },
        timeFormatOptions = {
          timeStyle: 'short',
        },
        displayStartDate = startDateTime.toLocaleDateString(locales, dateFormatOptions),
        displayStartTime = startDateTime.toLocaleTimeString(locales, timeFormatOptions);

  if (
    startDateTimeIsValid
    && !endDateTimeIsValid
  ) {
    return `${displayStartDate} // ${displayStartTime}`;
  }

  const displayEndDate = endDateTime.toLocaleDateString(locales, dateFormatOptions),
        displayEndTime = endDateTime.toLocaleTimeString(locales, timeFormatOptions);

  if (
    startDateTimeIsValid
    && endDateTimeIsValid
    && displayStartDate === displayEndDate
  ) {
    return `${displayStartDate} // ${displayStartTime} - ${displayEndTime}`;
  }

  return `${displayStartDate} ${displayStartTime} // ${displayEndDate} ${displayEndTime}`;
}

export function parseIdFromString(string) {
  return string
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(' ', '-')
    .toLowerCase();
}
